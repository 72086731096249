<template>
  <v-container fluid style="height: 100%;" class="pa-0 ma-0">
    <div class="header">
      <strong>
        {{ get_supplier_setup.supplier_name }} > New Product
      </strong>
    </div>
    <v-col cols="12" class="ma-0 pa-0">
      <v-tabs centered v-model="tab">
        <v-tab href="#tab-1" class="tabs"> Setup </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <Create />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Create from "./product/CreateProduct.vue";

export default {
  components: { Create },
  props: [],
  data: () => ({
    tab: null,
  }),
  mounted() { },
  created() { },
  computed: {
    ...mapGetters({
      get_supplier_setup: "supplier/get_supplier_setup",
    }),
  },
  methods: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.header {
  max-width: 100%;
  height: 50px;
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 50px;
}

.tabs {
  text-transform: none !important;
}
</style>
